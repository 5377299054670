import TitleImage from './resources/Title.png';
import LocationMapImage from './resources/LocationMap.png';
import GalleryPhoto1 from './resources/Gallery_Photo_1.png';
import GalleryPhoto2 from './resources/Gallery_Photo_2.png';
import GalleryPhoto3 from './resources/Gallery_Photo_3.png';
import GalleryPhoto4 from './resources/Gallery_Photo_4.png';
import GalleryPhoto5 from './resources/Gallery_Photo_5.png';
import GalleryPhoto6 from './resources/Gallery_Photo_6.png';
import GalleryPhoto7 from './resources/Gallery_Photo_7.png';
import GalleryPhoto8 from './resources/Gallery_Photo_8.png';
import GalleryPhoto9 from './resources/Gallery_Photo_9.png';
import GalleryPhoto10 from './resources/Gallery_Photo_10.png';
import GalleryPhoto11 from './resources/Gallery_Photo_11.png';
import GalleryPhoto12 from './resources/Gallery_Photo_12.png';
import GalleryPhoto13 from './resources/Gallery_Photo_13.png';
import GalleryPhoto14 from './resources/Gallery_Photo_14.png';
import GalleryPhoto15 from './resources/Gallery_Photo_15.png';
import GalleryPhoto16 from './resources/Gallery_Photo_16.png';
import GalleryPhoto17 from './resources/Gallery_Photo_17.png';
import GalleryPhoto18 from './resources/Gallery_Photo_18.png';
import GalleryPhoto19 from './resources/Gallery_Photo_19.png';
import GalleryPhoto20 from './resources/Gallery_Photo_20.png';
import GalleryPhoto21 from './resources/Gallery_Photo_21.png';
import GalleryPhoto22 from './resources/Gallery_Photo_22.png';

const Configs: ConfigsType = {
  url: 'https://sumyum.hopto.me',
  kakaoToken: '카카오톡 API 토큰을 입력해주세요!',
  kakaoImage: '카카오톡으로 공유할 때 사용되는 대표이미지 URL을 입력해주세요!',
  weddingDate: '2023년 09월 16일 토요일 오후 2시',
  weddingLocation: '유성컨벤션웨딩홀, 3층 그랜드홀',
  groom: {
    name: '이성연',
    accountNumber: '하나은행 653-910342-71607',
    fatherName: '이윤성',
    fatherAccountNumber: '농협 356-0961-2463-73',
    motherName: '손기연',
    motherAccountNumber: '농협 477-02-160157',
  },
  bride: {
    name: '이소열',
    accountNumber: '농협 352-1971-3355-73',
    fatherName: '이동길',
    fatherAccountNumber: '우체국 104679-02-190833',
    motherName: '최정숙',
    motherAccountNumber: '기업은행 345-043203-01-038',
  },
  titleImage: TitleImage,
  locationMapImage: LocationMapImage,
  galleryImages: [
    GalleryPhoto1,
    GalleryPhoto2,
    GalleryPhoto3,
    GalleryPhoto4,
    GalleryPhoto5,
    GalleryPhoto6,
    GalleryPhoto7,
    GalleryPhoto8,
    GalleryPhoto9,
    GalleryPhoto10,
    GalleryPhoto11,
    GalleryPhoto12,
    GalleryPhoto13,
    GalleryPhoto14,
    GalleryPhoto15,
    GalleryPhoto16,
    GalleryPhoto17,
    GalleryPhoto18,
    GalleryPhoto19,
    GalleryPhoto20,
    GalleryPhoto21,
    GalleryPhoto22,
  ],
};

export type ConfigsType = {
  url: string;
  kakaoToken: string;
  kakaoImage: string;
  weddingDate: string;
  weddingLocation: string;
  groom: Person;
  bride: Person;
  titleImage: string;
  locationMapImage: string;
  galleryImages: string[];
};

type Person = {
  name: string;
  accountNumber: string;
  fatherName?: string;
  fatherAccountNumber?: string;
  motherName?: string;
  motherAccountNumber?: string;
};

export default Configs;
