import { useRef } from 'react';
import { styled } from '@stitches/react';
import useOnScreen from '../hooks/useOnScreen';
import { ConfigsType } from '../configs';

const isPortrait = window.matchMedia('(orientation: portrait)').matches;

const Layout = styled('div', {
  width: '100%',
  padding: isPortrait ? '30% 0% 15% 5%' : '5% 0% 5% 10%',
});

const Title = styled('p', {
  color: '#795548',
  width: '100%',
  fontSize: isPortrait ? '2.5em' : '3.5em',
  margin: 0,
  fontWeight: '500',
});

const SubTitle = styled('p', {
  color: '#795548',
  width: '100%',
  fontSize: isPortrait ? '1.2em' : '2em',
  margin: '24px 0',
  fontWeight: '300',
  lineHeight: 1.8,
});

type GreetingProps = {
  config: ConfigsType;
};

const Greeting = ({ config }: GreetingProps) => {
  const ref = useRef<HTMLSelectElement>(null);
  const onScreen: boolean = useOnScreen<HTMLDivElement>(ref, '-125px');

  return (
    <section
      ref={ref}
      style={{
        height: '100vh',
        background: onScreen ? '#EFEBE9' : '#DADADA',
        overflow: 'hidden',
        position: 'relative',
        transition: 'background 1s ease-in',
      }}
    >
      <Layout>
        <Title>우리들의 첫 시작</Title>
        <SubTitle>
          {config.groom.name} & {config.bride.name}
          <br />
          결혼하는 날
          <br />
          <br />
          그녀를 집에 데려다 주는
          <br />
          일이 힘들기 시작했습니다.
          <br />
          <br />
          집 앞에서 그를 돌려보내는
          <br />
          일이 아쉽기 시작했습니다.
          <br />
          <br />
          그래서 저희는 세상에서 가장 포근하고
          <br />
          따뜻한 집을 지어 서로를 닮은 아이를 낳아
          <br />
          평생을 함께 눈뜨고 함께 잠들기로 약속하였습니다.
          <br />
          <br />
          부디 참석하시어 축복해 주시면 감사하겠습니다.
          <br />
          <br />
          {config.groom.fatherName} · {config.groom.motherName}의 장남 {config.groom.name}
          <br />
          {config.bride.fatherName} · {config.bride.motherName}의 차녀 {config.bride.name}
        </SubTitle>
      </Layout>
    </section>
  );
};

export default Greeting;
