import {useRef} from 'react';
import {styled} from '@stitches/react';
import {ConfigsType} from '../configs';
import {LinkOutlined, CopyOutlined, BranchesOutlined } from "@ant-design/icons";
import CopyToClipboard from "react-copy-to-clipboard";
import { Button, message } from 'antd';

const isPortrait = window.matchMedia('(orientation: portrait)').matches;

const Section = styled('section', {
    background: '#EFEBE9',
    overflow: 'hidden',
    position: 'relative',
});

const Layout = styled('div', {
    width: '100%',
    padding: isPortrait ? '20% 0% 15% 5%' : '5% 0% 5% 10%',
});

const Title = styled('p', {
    color: '#795548',
    width: '100%',
    fontSize: isPortrait ? '2.5em' : '3.5em',
    margin: 0,
    fontWeight: '500',
});

const SubTitle = styled('p', {
    color: '#795548',
    width: '100%',
    fontSize: isPortrait ? '1.2em' : '2em',
    margin: '24px 0',
    fontWeight: '300',
    lineHeight: 1.8,
});

const LinkShareButton = styled(Button, {
    background: '#8c9ba7',
    borderColor: '#8c9ba7',
    color: '#ffffff',
    '&:hover': {
        backgroundColor: '#9fcbed !important',
        borderColor: '#9fcbed !important',
        color: '#ffffff !important',
    },
    '&:focus': {
        backgroundColor: '#9fcbed !important',
        borderColor: '#9fcbed !important',
        color: '#ffffff !important',
    },
});

const MapOpenButton = styled(Button, {
    background: '#1EC800',
    borderColor: '#1EC800',
    color: '#ffffff',
    '&:hover': {
        backgroundColor: '#aadc82 !important',
        borderColor: '#aadc82 !important',
        color: '#ffffff !important',
    },
    '&:focus': {
        backgroundColor: '#aadc82 !important',
        borderColor: '#aadc82 !important',
        color: '#ffffff !important',
    },
});

type LocationProps = {
    config: ConfigsType;
};

const Location = ({config}: LocationProps) => {
    const ref = useRef<HTMLSelectElement>(null);

    return (
        <Section ref={ref}>
            <Layout>
                <Title>오시는 길</Title>
                <SubTitle>
                    대전광역시 유성구 온천북로 77
                    <br/>
                    (대전광역시 유성구 봉명동 692-4)
                    <br/>
                    전화번호 : 042-825-7070
                    <br/>
                    <br/>
                    시내버스로 오는 길
                    <br/>
                    102, 106, 108, 706, 113번
                    <br/>
                    홈플러스 유성점에서 하차
                    <br/>
                    <br/>
                    지하철로 오는 길
                    <br/>
                    1호선 갑천역 하차,
                    <br/>
                    3번출구 돌다리 건너편 7분 거리
                    <br/>
                    <br/>
                    전세버스
                    <br/>
                    9월 16일 오전 11시
                    <br/>
                    안산 상록수역 앞 롯데리아
                    <br/>
                    (상록수역 3번 출구 근처)
                    <br/>
                    인솔자 번호 : 010-5395-8411
                    <br/>
                    <br/>
                    <img
                        style={{width: isPortrait ? '90%' : '60%'}}
                        src={config.locationMapImage}
                        alt="Wedding Invitation Title Picutre"
                    />
                </SubTitle>
                <br/><br/><br/><br/><br/><br/>
                <Title>신랑 신부의 친구들과 지인분들을 위한 피로연 안내</Title>
                <br/><br/>
                <SubTitle>
                    피로연 일시
                    <br/>
                    <b>2023년 09월 16일 오후 6시부터 시작</b>
                    <br/>
                    (입장가능 시각 오후 5시 30분)
                    <br/>
                    <br/>
                    피로연 장소
                    <br/>
                    <b>올에어 대전유성점</b>
                    <br/>
                    대전광역시 유성구 온천북로 13번길 26 2층
                    <br/>
                    <CopyToClipboard text="대전광역시 유성구 온천북로 13번길 26 2층">
                        <LinkShareButton
                            style={{ margin: 8 }}
                            icon={<CopyOutlined />}
                            size="large"
                            onClick={() => message.success('연회장 주소가 복사되었습니다.')}
                        >
                            연회장 주소 복사하기
                        </LinkShareButton>
                    </CopyToClipboard>
                    <MapOpenButton
                        style={{ margin: 8 }}
                        icon={<BranchesOutlined  />}
                        size="large"
                        onClick={() => window.open('https://naver.me/59juScUI', '_blank')}
                    >
                        연회장 네이버 지도 열기
                    </MapOpenButton>
                </SubTitle> 
            </Layout>
        </Section>
    );
};

export default Location;
